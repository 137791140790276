import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from '../../utils/cn';
import { LoadingSpinner, LoadingSpinnerCircle } from '../loading-spinner';

const buttonVariants = cva(
  'cursor-pointer inline-flex items-center justify-center rounded-full transition-colors duration-300 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default:
          'bg-primary text-primary-foreground shadow hover:bg-primary/90 active:bg-primary/90',
        tableAction: 'bg-zinc-900 text-primary-foreground shadow hover:bg-zinc-800/90',
        destructive: 'bg-destructive text-destructive-foreground shadow-sm hover:bg-destructive/90',
        outline:
          'border-2 border-input bg-transparent shadow-sm hover:border-zinc-400 active:border-zinc-400 hover:text-accent-foreground dark:text-white dark:hover:text-white',
        secondary: 'bg-zinc-200 text-zinc-800 shadow-sm hover:bg-zinc-300',
        success: 'bg-green-500 text-white shadow-sm hover:bg-green-600',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        link: 'text-primary underline-offset-4 hover:underline',
        primary: 'bg-clippd-pink text-white shadow-sm hover:bg-clippd-pink-dark',
        warning: 'bg-warning text-black shadow-sm hover:bg-warning/80',
        none: '',
      },
      size: {
        default: 'h-10 px-6 py-3',
        xs: 'h-6 px-2 text-xs',
        sm: 'h-8 px-3 text-sm',
        lg: 'h-11 px-8',
        xl: 'h-14 px-8 py-4 text-lg font-bold',
        icon: 'h-9 w-9',
        'icon-xs': 'h-6 w-6',
        'icon-lg': 'h-11 w-11',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  loading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { className, variant, size, asChild = false } = props;
  const Comp = asChild ? Slot : ButtonDisplay;
  return (
    <Comp
      {...props}
      className={cn('relative', buttonVariants({ variant, size, className }))}
      ref={ref}
    />
  );
});
Button.displayName = 'Button';

const ButtonDisplay = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { children, disabled, loading, size } = props;

  return (
    <button {...props} ref={ref} disabled={disabled || loading}>
      {children}
      <div
        className={cn(
          'absolute inset-0 flex items-center justify-center text-center bg-white bg-opacity-50 rounded-full transition-all duration-200 pointer-events-none',
          {
            'opacity-0': !loading,
          }
        )}
      >
        {/icon/.test(size as string) ? (
          <LoadingSpinnerCircle size={36} />
        ) : (
          <LoadingSpinner width={60} suppressPadding />
        )}
      </div>
    </button>
  );
});

export { Button, buttonVariants };
