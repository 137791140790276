import { FC } from 'react';
import BarLoader from 'react-spinners/BarLoader';
import PuffLoader from 'react-spinners/PuffLoader';
import { LoaderHeightWidthProps, LoaderSizeProps } from 'react-spinners/helpers/props';
import { cn } from '../utils/cn';

export const LoadingSpinner: FC<
  LoaderHeightWidthProps & {
    suppressPadding?: boolean;
  }
> = ({ suppressPadding, color = '#002147', ...props }) => (
  <div className={cn({ 'p-4 lg:px-6': !suppressPadding })} data-testid="loading-spinner">
    <BarLoader {...props} color={color} />
  </div>
);

export const LoadingSpinnerCircle: FC<Omit<LoaderSizeProps, 'color'>> = (props) => (
  <div className="w-full h-full flex items-center justify-center" data-testid="loading-spinner">
    <PuffLoader {...props} color="#002147" />
  </div>
);
